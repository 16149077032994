import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { rhythm } from "../../utils/typography"

class MainIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`Contact Me`}
          keywords={[`Japanese`, `travel`, `personal development`, `Korean`, `blog`, `gatsby`, `javascript`, `react`]}
        />
        <h2 style={{ marginTop: rhythm(1.0) }}>Contact Me</h2>
        <Bio />
        <p>
          You can find my social media here: <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/wilsonmitchell">LinkedIn</a>, <a target="_blank" rel="noopener noreferrer" href="https://github.com/mitchellw">Github</a>, <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/WilsonMobile">Twitter</a>. If you've used something I've made or just want to get in touch, let's talk! You can contact me using the links below:
        </p>
        <ul>
          <li><a href="mailto:bug-report@wilsonmitchell.me?subject=Bug Report&body=I have a bug report for ______.">Send Bug Report</a></li>
          <li><a href="mailto:feature-request@wilsonmitchell.me?subject=Feature Request&body=I have a feature request for ______.">Send Feature Request</a></li>
          <li><a href="mailto:hi@wilsonmitchell.me?subject=Hi&body=">Get In Contact</a></li>
        </ul>
      </Layout>
    )
  }
}

export default MainIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
